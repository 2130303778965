import React, { MouseEvent, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { Duration } from "api/appointment";
import { ErrorSummary, Hint, Label, Radios } from "nhsuk-react-components";
import {
  IAppointmentSlot,
  IAppointmentSlotWithAvailability,
  IAvailabilityLocation,
} from "interfaces";
import { Loader } from "components";
import { Restricted } from "auth/components";
import { T } from "i18n";
import { TabSet } from "nhsuk-react-components-extensions";
import { formatTime } from "formatters";

type DateFilterFn = (_: any, index: number) => boolean;

type DateFilter = {
  fn: DateFilterFn;
  toggleLinkText: string;
  heading: string;
};

const showNext5: DateFilter = {
  fn: (_, index: number) => index < 5,
  toggleLinkText: T("containers.booking.fields.slot.toggleLink.showNext5"),
  heading: T("containers.booking.fields.slot.heading.showNext5"),
};

const showAll: DateFilter = {
  fn: () => true,
  toggleLinkText: T("containers.booking.fields.slot.toggleLink.showAll"),
  heading: T("containers.booking.fields.slot.heading.showAll"),
};

function timesPerDay(
  appointmentSlots: IAppointmentSlotWithAvailability[]
): Record<string, IAppointmentSlotWithAvailability[]> {
  return appointmentSlots.reduce(
    (groups: Record<string, IAppointmentSlotWithAvailability[]>, item) => {
      const day = item.from.startOf("day").toISODate();
      groups[day] ||= [];
      groups[day].push(item);
      return groups;
    },
    {}
  );
}

export const SlotPicker = ({
  location,
  date,
  selectSlot,
  selectedSlot,
  duration,
  setDuration,
  defaultDuration,
  loading,
  error,
  setDate,
  setError,
  setShowNext,
}: {
  location: IAvailabilityLocation;
  date?: DateTime;
  selectedSlot?: IAppointmentSlot;
  selectSlot: (slot: IAppointmentSlot | undefined) => void;
  setDate: (date: DateTime | undefined) => void;
  duration: Duration;
  setDuration: (duration: Duration) => void;
  defaultDuration: Duration;
  loading: boolean;
  error: string;
  setError: (message: string) => void;
  setShowNext: (val: boolean) => void;
}): JSX.Element => {
  const [dateFilter, setDateFilter] = useState<DateFilter>(showNext5);

  const toggleDateFilter = (e: MouseEvent): void => {
    e.preventDefault();
    const filter = dateFilter === showAll ? showNext5 : showAll;
    setDateFilter(filter);
  };

  useEffect(() => {
    if (date !== undefined) {
      const viewableDays = Object.entries(
        timesPerDay(location.appointmentSlots)
      )
        .filter(dateFilter.fn)
        .map(([date]) => date);

      if (!viewableDays.includes(date.toISODate())) {
        setDateFilter(showAll);
      }
    }
  }, [location, date, dateFilter]);

  const updateSlot = (
    slot: IAppointmentSlotWithAvailability | undefined
  ): void => {
    setError("");

    let selectedSlot: IAppointmentSlot | undefined;

    if (slot) {
      selectedSlot = {
        address: location.address,
        from: slot.from,
        to: slot.to,
        unitCode: location.unitCode,
      };
    }

    selectSlot(selectedSlot);
  };

  const days = Object.entries(timesPerDay(location.appointmentSlots))
    .filter(dateFilter.fn)
    .map(([day, slots]) => {
      const morningSlots = slots.some((slot) => slot.from.hour <= 12);
      const afternoonSlots = slots.some((slot) => slot.from.hour > 12);
      const times = slots.map((slot) => (
        <Radios.Radio
          key={slot.from.toISOTime()}
          data-testid={formatTime(slot.from)}
          value={slot.from.toISOTime()}
          onChange={() => updateSlot(slot)}
          defaultChecked={selectedSlot && slot.from === selectedSlot.from}
        >
          {formatTime(slot.from, "h:mma")}
        </Radios.Radio>
      ));

      const daySelected = (): void => {
        updateSlot(undefined);
        if (day === date?.toISODate()) {
          setDate(undefined);
          return;
        }
        setDate(DateTime.fromISO(day));
      };

      return (
        <Radios.Radio
          role="appointment-date"
          key={day}
          data-testid={day}
          value={day}
          conditional={<Radios>{times}</Radios>}
          checked={day === date?.toISODate()}
          onClick={daySelected}
        >
          <p className="nhsuk-u-margin-bottom-1">
            {DateTime.fromISO(day).toFormat("cccc d LLLL")}
          </p>
          {morningSlots && (
            <Hint data-testid="morningAvailable" className="nhsuk-u-margin-0">
              {" "}
              {T("containers.booking.fields.slot.morningAvailable")}
            </Hint>
          )}
          {afternoonSlots && (
            <Hint data-testid="afternoonAvailable" className="nhsuk-u-margin-0">
              {" "}
              {T("containers.booking.fields.slot.afternoonAvailable")}
            </Hint>
          )}
        </Radios.Radio>
      );
    });

  useEffect(() => {
    setShowNext(!loading && days.length > 0);
  }, [loading, days, setShowNext]);

  return (
    <>
      {error !== "" ? (
        <ErrorSummary>
          <ErrorSummary.Title>
            {T("containers.booking.fields.slot.errors.title")}
          </ErrorSummary.Title>
          <ErrorSummary.List>
            <ErrorSummary.Item href="#radios">{error}</ErrorSummary.Item>
          </ErrorSummary.List>
        </ErrorSummary>
      ) : (
        <></>
      )}
      <Label size="m">{dateFilter.heading}</Label>
      <Restricted
        allowedRoles={[
          "KCL Admin",
          "Call Centre Agent",
          "EMS Central Team",
          "EMS Unit Staff",
          "Auditor",
        ]}
        mode="allowRoles"
      >
        <TabSet style={{ backgroundColor: "inherit" }}>
          <TabSet.Tab style={{ backgroundColor: "inherit" }}>
            {T("containers.booking.fields.slot.tabs.title")}
          </TabSet.Tab>
          {[1, 2, 3]
            .map((i) => defaultDuration * i)
            .map((durationOption) => (
              <TabSet.Tab
                key={durationOption}
                style={{ backgroundColor: "inherit" }}
                onClick={() => setDuration(durationOption as Duration)}
                selected={duration === durationOption}
                active={duration === durationOption}
                disabled={loading}
                data-testid={`${durationOption}-mins-tab`}
              >
                {T("containers.booking.fields.slot.duration", {
                  duration: durationOption,
                })}
              </TabSet.Tab>
            ))}
        </TabSet>
        <br />
      </Restricted>
      {loading && <Loader />}
      {!loading && days.length > 0 && (
        <>
          <Radios id="radios" error={error} data-testid="slot-radios">
            {days}
          </Radios>
          <div className="nhsuk-u-padding-bottom-3">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              data-testid="toggle-visible-dates"
              onClick={toggleDateFilter}
            >
              {dateFilter.toggleLinkText}
            </a>
          </div>
        </>
      )}
      {!loading && days.length === 0 && (
        <p data-testid="no-slot-duration-error">
          {T("containers.booking.noSlotsForDuration", { duration })}
        </p>
      )}
    </>
  );
};
