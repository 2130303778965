export type Role = typeof AllRoles[number];

export const AllRoles = [
  "KCL Admin",
  "KCL Scheduling Support",
  "KCL Research Nurse",
  "Call Centre Agent",
  "EMS Central Team",
  "EMS Unit Staff",
  "GRAIL User",
  "GRAIL User (Unblinded)",
  "KCL Admin (Unblinded)",
  "KCL Monitor",
  "Auditor",
] as const;

export type IUser = {
  id: string;
  roles: Role[];
  firstName?: string;
  lastName?: string;
};
