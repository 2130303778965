import React, { useEffect, useMemo, useState } from "react";
import { BloodStatus } from "./BloodStatus";
import {
  Bookability,
  IAppointment,
  IDataDeletionStatus,
  IPerson,
} from "interfaces";
import { DeleteInvitee } from "./DeleteInvitee";
import { DeletionStatus } from "enums";
import { ErrorPanel, FeatureFlag } from "components";
import { PersonAppointments } from "./PersonAppointments";
import { PersonDetails, PersonFields } from "./PersonDetails";
import { PersonNotes } from "./PersonNotes";
import { Restricted } from "auth/components";
import { useAPI } from "api";

import { AsyncCall, err, loading, notCalled, ok } from "helpers/AsyncCall";
import { StudyEvents } from "./StudyEvents";
import { T } from "i18n";
import { WithdrawParticipant } from "./WithdrawParticipant";
import { hasRole } from "auth/hasRole";
import { ContactStatus } from "api/people";
import { Label } from "nhsuk-react-components";
import { Notifications } from "./Notifications";
import { PersonDeceasedStatus } from "./PersonDeceasedStatus";
import { StudyEndpointOutsideEngland } from "containers/Person/StudyEndpointOutsideEngland";
import { useAuth } from "auth";
import { useHistory, useParams } from "react-router";
import { PersonCommunications } from "./PersonCommunications";
import { StudyEndpointNotParticipating } from "./StudyEndpointNotParticipating";
import { StudyEndpointSelfReportedCancer } from "containers/Person/StudyEndpointSelfReportedCancer";

export const Person = (): JSX.Element => {
  const { cohortId } = useParams<{ cohortId: string }>();
  const { user } = useAuth();
  const personAPI = useAPI("people");
  const [person, setPerson] = useState<IPerson>();
  const [loadPersonError, setLoadPersonError] = useState<Error>();

  const history = useHistory();

  const displayFields = new Set<PersonFields>([
    "dateOfBirth",
    "mailingAddress",
    "phoneNumber",
    "mobilePhoneNumber",
    "email",
    "gpPractice",
    "gpName",
    "dataSharingAgreementVersion",
    "studyStatus",
    "cohortId",
    "eligibilityStatus",
    "age",
    "y1Anniversary",
    "y2Anniversary",
    "nhsNumber",
    "studyId",
  ]);

  useEffect((): void => {
    personAPI.getPerson(cohortId).then(setPerson).catch(setLoadPersonError);
  }, [personAPI, cohortId]);

  const isParticipant = useMemo(() => person?.studyId, [person]);
  const withdrawn = useMemo(() => person?.withdrawn, [person]);
  const isDeceased = useMemo(() => person?.deceased, [person]);

  const appointmentAPI = useAPI("appointment");

  const [appointments, setappointments] =
    useState<AsyncCall<IAppointment[]>>(notCalled);

  useEffect((): void => {
    setappointments(loading);
    appointmentAPI
      .getAppointments(cohortId)
      .then((result) => setappointments(ok(result)))
      .catch((error) => setappointments(err(error)));
  }, [cohortId, appointmentAPI]);

  const eligibilityAPI = useAPI("eligibility");

  const [isEligible, setIsEligible] = useState<boolean>();

  useEffect((): void => {
    eligibilityAPI
      .getEligibility(cohortId)
      .then((result) => {
        if (result.eligible !== undefined) setIsEligible(result.eligible);
      })
      .catch((error) => error);
  }, [cohortId, eligibilityAPI]);

  const [contactStatus, setContactStatus] =
    useState<AsyncCall<ContactStatus>>(notCalled);

  useEffect((): void => {
    if (hasRole(user, ["Call Centre Agent", "Auditor"])) {
      setContactStatus(loading);
      personAPI
        .getContactStatus(cohortId)
        .then((result) => setContactStatus(ok(result)))
        .catch((error) => setContactStatus(err(error)));
    }
  }, [cohortId, personAPI, user]);

  const [canBook, setcanBook] = useState<AsyncCall<Bookability>>(notCalled);

  const onBookAppointment = async (): Promise<void> => {
    setcanBook(loading);
    appointmentAPI
      .checkCanBook(cohortId)
      .then((result) => setcanBook(ok(result)))
      .catch((error) => setcanBook(err(error)));
  };

  const onBloodStatusChange = (): void => {
    setcanBook(notCalled);
  };

  const dataAPI = useAPI("data");
  const [canDelete, setCanDelete] = useState<IDataDeletionStatus | null>(null);
  useEffect((): void => {
    if (hasRole(user, ["KCL Admin", "Auditor"])) {
      dataAPI
        .checkDelete(cohortId)
        .then((result) => setCanDelete(result))
        .catch(setLoadPersonError);
    }
  }, [dataAPI, cohortId, user]);

  return (
    <>
      {loadPersonError && (
        <ErrorPanel title={T("components.person.errors.loadPerson.title")}>
          {loadPersonError.message}
        </ErrorPanel>
      )}
      <main className="nhsuk-main-wrapper">
        {person && (
          <>
            <Label isPageHeading data-testid="name">
              {`${person.firstName} ${person.lastName}`}
            </Label>
            <PersonDetails
              person={person}
              setPerson={setPerson}
              displayFields={displayFields}
              isEligible={isEligible}
            />
          </>
        )}
        <FeatureFlag flag="Y1_ENABLED">
          <Restricted
            allowedRoles={[
              "KCL Admin",
              "Call Centre Agent",
              "EMS Unit Staff",
              "EMS Central Team",
              "Auditor",
            ]}
            mode="allowRoles"
          >
            <StudyEvents cohortId={cohortId} />
          </Restricted>
        </FeatureFlag>
        <Restricted
          allowedRoles={[
            "KCL Admin",
            "EMS Central Team",
            "EMS Unit Staff",
            "KCL Research Nurse",
            "Auditor",
          ]}
          mode="allowRoles"
        >
          <BloodStatus cohortId={cohortId} onChange={onBloodStatusChange} />
        </Restricted>
        <Restricted
          allowedRoles={["Call Centre Agent", "Auditor"]}
          mode="allowRoles"
        >
          <Notifications
            contactStatus={contactStatus}
            onEdit={() => {
              history.push(`/participant/${cohortId}/notifications`);
            }}
          />
        </Restricted>
        <PersonAppointments
          cohortId={cohortId}
          appointments={appointments}
          canBook={canBook}
          onBookAppointment={onBookAppointment}
        />
        <Restricted
          allowedRoles={[
            "Call Centre Agent",
            "KCL Admin",
            "KCL Admin (Unblinded)",
            "KCL Research Nurse",
            "Auditor",
          ]}
          mode="allowRoles"
        >
          <PersonCommunications cohortId={cohortId} />
        </Restricted>
        {isParticipant && !withdrawn && (
          <Restricted
            allowedRoles={["EMS Unit Staff", "KCL Research Nurse", "Auditor"]}
            mode="allowRoles"
          >
            <WithdrawParticipant cohortId={cohortId} />
          </Restricted>
        )}
        {isDeceased && (
          <Restricted
            allowedRoles={["KCL Research Nurse", "Auditor"]}
            mode="allowRoles"
          >
            <PersonDeceasedStatus cohortId={cohortId} />
          </Restricted>
        )}
        <Restricted allowedRoles={["KCL Admin", "Auditor"]} mode="allowRoles">
          {canDelete?.status === DeletionStatus.PERMITTED && (
            <DeleteInvitee cohortId={cohortId} />
          )}
        </Restricted>
        <Restricted
          allowedRoles={[
            "Call Centre Agent",
            "KCL Research Nurse",
            "KCL Admin (Unblinded)",
            "Auditor",
          ]}
          mode="allowRoles"
        >
          <h4>{T("components.person.studyEndpoint.title")}</h4>
          <Restricted
            allowedRoles={["Call Centre Agent", "Auditor"]}
            mode="allowRoles"
          >
            {person && <StudyEndpointOutsideEngland person={person} />}
          </Restricted>
          <Restricted
            allowedRoles={[
              "KCL Research Nurse",
              "KCL Admin (Unblinded)",
              "Auditor",
            ]}
            mode="allowRoles"
          >
            {person && (
              <StudyEndpointSelfReportedCancer cohortId={person.cohortId} />
            )}
          </Restricted>
          {process.env.REACT_APP_NO_LONGER_PARTICIPATING === "true" &&
            person && (
              <StudyEndpointNotParticipating cohortId={person.cohortId} />
            )}
        </Restricted>
        <PersonNotes cohortId={cohortId} />
      </main>
    </>
  );
};
