import React, { useEffect, useState } from "react";
import {
  ActionLink,
  BackLink,
  InsetText,
  Label,
  SummaryList,
  WarningCallout,
} from "nhsuk-react-components";
import { Link, useLocation, useParams } from "react-router-dom";

import { Address, ErrorPanel, Loader } from "components";
import { IAppointment } from "interfaces";
import { Restricted } from "auth/components";
import { T } from "i18n";
import { formatTime } from "formatters";
import { useAPI } from "api";

const appointmentDurationInMinutes = (appointment: IAppointment): number => {
  return appointment.appointmentEndTime
    .diff(appointment.appointmentStartTime)
    .as("minutes");
};

export const AppointmentSummary = ({
  name,
  id,
  appointment,
  unitLocationDirections,
}: {
  name?: string;
  id: string;
  appointment: IAppointment;
  unitLocationDirections?: string;
}): JSX.Element => (
  <SummaryList>
    {name ? (
      <SummaryList.Row>
        <SummaryList.Key>
          {T("containers.bookingConfirmation.fields.name.label")}
        </SummaryList.Key>
        <SummaryList.Value data-testid="booking-confirmation-name">
          {name}
        </SummaryList.Value>
      </SummaryList.Row>
    ) : (
      <></>
    )}
    <SummaryList.Row>
      <SummaryList.Key>
        {T("containers.bookingConfirmation.fields.reference.label")}
      </SummaryList.Key>
      <SummaryList.Value data-testid="booking-confirmation-reference">
        {id}
      </SummaryList.Value>
    </SummaryList.Row>
    <SummaryList.Row>
      <SummaryList.Key>
        {T("containers.bookingConfirmation.fields.dateAndTime.label")}
      </SummaryList.Key>
      <SummaryList.Value data-testid="booking-confirmation-dateAndTime">
        <p>
          {appointment.appointmentStartTime.toFormat("cccc dd LLLL yyyy")}
          <br />
          {formatTime(appointment.appointmentStartTime, "h:mma").toLowerCase()}
        </p>
      </SummaryList.Value>
    </SummaryList.Row>
    {appointment.type === "D1" && (
      <Restricted
        allowedRoles={[
          "KCL Admin",
          "Call Centre Agent",
          "EMS Central Team",
          "EMS Unit Staff",
          "Auditor",
        ]}
        mode="allowRoles"
      >
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.booking.fields.slot.tabs.title")}
          </SummaryList.Key>
          <SummaryList.Value data-testid="booking-confirmation-duration">
            <p>
              {T("containers.booking.fields.slot.duration", {
                duration: appointmentDurationInMinutes(appointment).toString(),
              })}
            </p>
          </SummaryList.Value>
        </SummaryList.Row>
      </Restricted>
    )}
    <SummaryList.Row>
      <SummaryList.Key>
        {T("containers.bookingConfirmation.fields.address.label")}
      </SummaryList.Key>
      <SummaryList.Value data-testid="booking-confirmation-location">
        <Address address={appointment.appointmentLocation} />
      </SummaryList.Value>
    </SummaryList.Row>
    {unitLocationDirections && (
      <SummaryList.Row>
        <SummaryList.Key>
          {T(
            "containers.bookingConfirmation.fields.unitLocationDirections.label"
          )}
        </SummaryList.Key>
        <SummaryList.Value data-testid="booking-confirmation-directions">
          <span style={{ whiteSpace: "pre-wrap" }}>
            {unitLocationDirections}
          </span>
        </SummaryList.Value>
      </SummaryList.Row>
    )}
  </SummaryList>
);

export const AppointmentConfirmationTextD1 = ({
  appointment,
}: {
  appointment: IAppointment;
}): JSX.Element => (
  <div>
    <Label data-testid="appointmentLetter" size="l">
      {T("containers.bookingConfirmation.appointmentLetter.title")}
    </Label>
    <p data-testid="booking-confirmation-message">
      {T("containers.bookingConfirmation.messages.success")}
    </p>

    <p data-testid="booking-confirmation-expectPost">
      {T("containers.bookingConfirmation.messages.participantInformationSheet")}
    </p>
    <p data-testid="download-pis">
      <a
        data-testid="download-pis-link"
        href={`${process.env.PUBLIC_URL}/documents/GRAIL_009_Participant_Information_Sheet_v3.0_25JUN2021.pdf`}
        download
      >
        {T(
          "containers.bookingConfirmation.messages.participantInformationSheetLink"
        )}
      </a>
    </p>
    <p data-testid="booking-confirmation-participantInformationSheetCopy">
      {T(
        "containers.bookingConfirmation.messages.participantInformationSheetCopy"
      )}
    </p>

    <InsetText>
      <p>
        {T("containers.bookingConfirmation.messages.consentFormCompletion")}
      </p>
      <p>
        {T("containers.bookingConfirmation.messages.bringAppointmentLetter")}
      </p>
    </InsetText>

    <Label data-testid="onTheDay" size="l">
      {T("containers.bookingConfirmation.onTheDay.title")}
    </Label>
    <p>{T("containers.bookingConfirmation.onTheDay.description")}</p>
    <p>
      {T("containers.bookingConfirmation.onTheDay.items.whatYouNeedToBring")}
    </p>
    <ul>
      <li>
        {T(
          "containers.bookingConfirmation.onTheDay.items.yourAppointmentLetter"
        )}
      </li>
      <li>
        {T("containers.bookingConfirmation.onTheDay.items.aFaceCovering")}
      </li>
    </ul>

    <Label data-testid="appointment" size="m">
      {T("containers.bookingConfirmation.yourAppointment.title")}
    </Label>
    <p data-testid="appointment-description">
      {T("containers.bookingConfirmation.yourAppointment.description", {
        durationMin: appointmentDurationInMinutes(appointment),
        durationMax: appointmentDurationInMinutes(appointment) + 15,
      })}
    </p>
    <ul>
      <li>
        {T(
          "containers.bookingConfirmation.yourAppointment.items.beingCheckedIn"
        )}
      </li>
      <li>
        {T(
          "containers.bookingConfirmation.yourAppointment.items.answeringQuestions"
        )}
      </li>
      <li>
        {T("containers.bookingConfirmation.yourAppointment.items.givingBlood")}
      </li>
      <li>
        {T(
          "containers.bookingConfirmation.yourAppointment.items.answeringSurveys"
        )}
      </li>
    </ul>

    <Label data-testid="covid" size="l">
      {T("containers.bookingConfirmation.covid.title")}
    </Label>
    <p>{T("containers.bookingConfirmation.covid.description")}</p>
    <ul>
      <li>{T("containers.bookingConfirmation.covid.items.highTemperature")}</li>
      <li>{T("containers.bookingConfirmation.covid.items.newCough")}</li>
      <li>{T("containers.bookingConfirmation.covid.items.lostOfSmell")}</li>
    </ul>
    <p>{T("containers.bookingConfirmation.covid.items.footer")}</p>

    <WarningCallout
      data-testid="important"
      label={T("containers.bookingConfirmation.important.title")}
    >
      <WarningCallout.Label>
        {T("containers.bookingConfirmation.important.title")}
      </WarningCallout.Label>
      <p>{T("containers.bookingConfirmation.important.description")}</p>
    </WarningCallout>

    <Label data-testid="cancelling-your-appointment" size="l">
      {T("containers.bookingConfirmation.cancellingYourAppointment.title")}
    </Label>
    <p>
      {T("containers.bookingConfirmation.cancellingYourAppointment.paragraph1")}
    </p>
    <p>
      {T("containers.bookingConfirmation.cancellingYourAppointment.paragraph2")}
    </p>
  </div>
);

export const AppointmentConfirmationTextYX = ({
  appointment,
}: {
  appointment: IAppointment;
}): JSX.Element => (
  <div data-testid="yx-appointment-confirmation-text">
    <Label size="l">
      {T("containers.bookingConfirmation.yx.reminders.heading")}
    </Label>
    <p>{T("containers.bookingConfirmation.yx.reminders.line1")}</p>
    <p>{T("containers.bookingConfirmation.yx.reminders.line2")}</p>
    <p>{T("containers.bookingConfirmation.yx.reminders.line3")}</p>

    <Label size="l">
      {T("containers.bookingConfirmation.yx.info.heading")}
    </Label>
    <p>{T("containers.bookingConfirmation.yx.info.line1")}</p>
    <p>{T("containers.bookingConfirmation.yx.info.line2")}</p>
    <ul>
      <li>{T("containers.bookingConfirmation.yx.info.list1.line1")}</li>
      <li>{T("containers.bookingConfirmation.yx.info.list1.line2")}</li>
      <li>{T("containers.bookingConfirmation.yx.info.list1.line3")}</li>
    </ul>
    <p>{T("containers.bookingConfirmation.yx.info.line3")}</p>
    <ul>
      <li>
        {T("containers.bookingConfirmation.yx.info.list2.line1Start")}
        <a
          href={T("containers.bookingConfirmation.yx.info.list2.link1")}
          target="_blank"
          rel="noopener noreferrer"
        >
          {T("containers.bookingConfirmation.yx.info.list2.link1")}
        </a>
        {T("containers.bookingConfirmation.yx.info.list2.line1End")}
      </li>
      <li>
        {T("containers.bookingConfirmation.yx.info.list2.line2Start")}
        <a
          href={T("containers.bookingConfirmation.yx.info.list2.link2")}
          target="_blank"
          rel="noopener noreferrer"
        >
          {T("containers.bookingConfirmation.yx.info.list2.link2")}
        </a>
        {T("containers.bookingConfirmation.yx.info.list2.line2End")}
      </li>
    </ul>
    <p>
      {T("containers.bookingConfirmation.yx.info.line4")}
      <a
        href={T("containers.bookingConfirmation.yx.info.link")}
        target="_blank"
        rel="noopener noreferrer"
      >
        {T("containers.bookingConfirmation.yx.info.link")}
      </a>
    </p>
    <>
      <Label size="l" data-testid="yx-changing-appointment-heading">
        {T("containers.bookingConfirmation.yx.changing.heading")}
      </Label>
      <p>{T("containers.bookingConfirmation.yx.changing.line1")}</p>
      <ActionLink
        asElement={Link}
        data-testid="yx-book-new-appointment-action"
        aria-label={T("containers.bookingConfirmation.yx.changing.action")}
        to={`/registration/${appointment.cohortId}/appointment?isRebooking=true&appointmentType=${appointment.type}`}
      >
        {T("containers.bookingConfirmation.yx.changing.action")}
      </ActionLink>
      <Label size="m">
        {T("containers.bookingConfirmation.yx.changing.cancelling.heading")}
      </Label>
      <p>
        {T("containers.bookingConfirmation.yx.changing.cancelling.line1start")}
        <a
          href={T(
            "containers.bookingConfirmation.yx.changing.cancelling.email"
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          {T("containers.bookingConfirmation.yx.changing.cancelling.email")}
        </a>
        {T("containers.bookingConfirmation.yx.changing.cancelling.line1end")}
      </p>
    </>

    <Label size="l">
      {T("containers.bookingConfirmation.yx.covid.heading")}
    </Label>
    <p>{T("containers.bookingConfirmation.yx.covid.line1")}</p>
  </div>
);

export const BookingConfirmation = (): JSX.Element => {
  const { appointmentId } = useParams<{
    appointmentId: string;
  }>();

  const { state: { appointment, unitLocationDirections } = {} } = useLocation<{
    appointment?: IAppointment;
    unitLocationDirections?: string;
  }>();

  const [name, setName] = useState<string>();
  const [loading, setLoading] = useState(false);

  const peopleAPI = useAPI("people");

  useEffect(() => {
    setLoading(true);
    peopleAPI
      .getPerson(appointmentId)
      .then((person) => {
        setName(`${person.firstName}  ${person.lastName}`);
      })
      .finally(() => setLoading(false));
  }, [appointmentId, peopleAPI]);

  if (loading) {
    return <Loader panel={false} />;
  }

  if (!appointmentId || !appointment || !name) {
    return (
      <ErrorPanel
        label=""
        title={
          <span data-testid="booking-error-summary-title">
            {T("containers.bookingConfirmation.errorSummary.title")}
          </span>
        }
      >
        <p data-testid="booking-error-summary-description">
          {T("containers.bookingConfirmation.errorSummary.description")}
        </p>
        <BackLink
          asElement={Link}
          to="/"
          data-testid="booking-error-summary-action-goToMain"
        >
          {T("containers.bookingConfirmation.errorSummary.actions.goToMain")}
        </BackLink>
      </ErrorPanel>
    );
  }

  return (
    <div className="nhsuk-u-padding-5">
      <Label size="xl" data-testid="booking-confirmation-title">
        {T("containers.bookingConfirmation.title")}
      </Label>

      {(appointment.type === "Y1" || appointment.type === "Y2") && (
        <p data-testid="booking-confirmation-yx-message">
          {T("containers.bookingConfirmation.yx.thankYou")}
        </p>
      )}

      <AppointmentSummary
        name={name}
        id={appointmentId}
        appointment={appointment}
        unitLocationDirections={unitLocationDirections}
      />

      {appointment.type === "Y1" || appointment.type === "Y2" ? (
        <AppointmentConfirmationTextYX appointment={appointment} />
      ) : (
        <AppointmentConfirmationTextD1 appointment={appointment} />
      )}
    </div>
  );
};
